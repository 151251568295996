import axios from 'axios'
import store from '@/store'
import { reset, getRecords } from '@/js/app/functions.js'
import { showLoading, showMessage, closeMessage } from '@/js/app/messages.js'

const getEvents = (payload ) => {
    showLoading()
    const url =`${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/events`
    axios.post(url, {})
        .then(res => {
            payload.gridEvents = res.data[0]['events']
            closeMessage()
        })
        .catch(error => {
            showMessage(error.response)
        }) 
}


const getCustomEvents = (options,filter) => {
    let cEvent = []
    if (Object.entries(options).length > 0) {        
        cEvent = options.filter(e => e.event === filter)
        if (Object.entries(cEvent).length > 0) {
            return cEvent[0]
        } else {
            return {}
        }
    }
}

const executeCustomEvent = (payload, event) => {
    showLoading()
    let method = event.method  
    let param = {...event.record}
    
    const url = `${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/${method}` 
    axios.post(url, param)
        .then(() => {
            reset(payload)
            getRecords(payload)  
            closeMessage()
        })
        .catch(error => {
            reset(payload)
            //getRecords(payload)  
            payload.customEvent = {}
            showMessage(error.response)
        })
}

export default {getEvents, getCustomEvents, executeCustomEvent}
export {getEvents, getCustomEvents, executeCustomEvent}