import store from '@/store'

let buttonControl = {
    add: false,
    save: false,
    cancel: false,
    delete: false
}

const setButtonInit = (payload) => {  
    if(store.state.userAuth.owner === true || payload.COMPONENT.config.allowCrud === true) {
        buttonControl.add = false
    } else {
        buttonControl.add = true
    }
    buttonControl.save = true
    buttonControl.cancel = true
    buttonControl.delete = true
}

const setButtonNewRow = () => {
    buttonControl.add = true
    buttonControl.save = false
    buttonControl.cancel = false
}


const setButtonEdit = (payload) => {
    buttonControl.add = true
    if(payload.COMPONENT.typeAccess === 'view'){
        buttonControl.save = true
        buttonControl.cancel = false
        buttonControl.delete = true
    } else {
        if(store.state.userAuth.owner === true || payload.COMPONENT.config.allowCrud === true) {
        buttonControl.save = false
        buttonControl.cancel = false
        buttonControl.delete = false
        } else if (store.state.userAuth.owner === false ) {
            buttonControl.cancel = false
        }
    }



}




/*const setButtonControl = (payload) => {
    if(payload === 'init') {
        buttonControl.add = false
        buttonControl.save = true
        buttonControl.cancel = true
        buttonControl.delete = true
    } else if(payload === 'edit' ) {
        buttonControl.add = true
        buttonControl.save = store.state.userAuth.owner === false ? true:false
        buttonControl.cancel = false
        buttonControl.delete = store.state.userAuth.owner === false ? true:false
    }else if(payload === 'view' ) {
        buttonControl.add = true
        buttonControl.save = true
        buttonControl.cancel = true
        buttonControl.delete = true
    }
}*/

export default { buttonControl, setButtonInit, setButtonNewRow, setButtonEdit }
export { buttonControl, setButtonInit, setButtonNewRow, setButtonEdit }
