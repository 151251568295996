<template>
  <div>
    <CCard>
      <CCardHeader class="_card-header-grid" >
        <i :class="title.icon"></i> {{title.description}}
      </CCardHeader>
      <CCardBody :class="title.class">
        <CDataTable :hover="hover" :striped="striped" :border="border" :small="small" :fixed="fixed"  pagination :dark="dark"        
          :items-per-page="small ? 10 : 5" :items="items" :fields="fields" @row-dblclicked="gridDbClick" :class="title.class" 
          :noItemsView="noItemsView" responsive >
          
          <template #action="{item}" >
            <td class="_table-background _table-cell-default-action" >
              <CButton size="sm" color="blue-dark" class="_btn-grid" @click="gridRowSelected(item)">
                <i class="fas fa-pencil-alt"></i> 
              </CButton>
            </td>
          </template>

          <template #find="{item}" >
            <td class="_table-background _table-cell-default-action">
              <CButton size="sm" color="blue-dark" class="_btn-grid" @click="findRowSelected(item)">
                <i class="fas fa-hand-pointer"></i>
              </CButton>
            </td>
          </template>         

          <template #delete="{item}" >
            <td class="_table-background _table-cell-default-action">
              <CButton size="sm" color="blue-dark" class="_btn-grid" @click="deleteRowSelected(item)">
                <i class="fas fa-minus-circle"></i>
              </CButton>
            </td>
          </template>    

          <template #buttons="{item}">
            <td class="_table-background _table-cell-others-action" >
              <CButton size="sm" color="orange" class="_btn-grid-w100" v-if="getEvent(item.ie_status) !== null" @click="gridRowActionSelected(item)" >
                <i class="fas fa-cog"></i>
                {{getEvent(item.ie_status)}}
              </CButton>
            </td>
          </template>    

          <template #conditions="{item}">
            <td class="bp-table-background bp-table-cell-others-action" >
              <CDropdown color="secondary" size="sm" split toggler-text="AÇÕES" >
                <CDropdownItem v-for="(event, index) in checkCondition(item)" :key="index"
                  @click="setEvent(event,item)">
                  {{ event.label }}
                </CDropdownItem>
              </CDropdown>
            </td>
          </template>    

          <template #subtitles="{item}" >
            <td class="_table-background _table-cell-subtitle">
              <div class="_table-cell-subtitle-content">
                <i class="fas fa-circle fa-lg" v-bind:class="getClass(item.ie_status)"></i> <span class="pl-2">{{item.ie_status}}</span>
              </div>
            </td>
          </template>          
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import fn from "@/js"

export default {
  name: 'FormGrid',
  props: {
    endPoint: String,
    items: Array,
    fields: {
      type: Array
    },
    hover: {
      type: Boolean,
      default: true
    },
    striped: Boolean,
    border: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: true
    },
    fixed: Boolean,
    dark: Boolean,
    title: {
      type: Object,
      default() {
        return {
          description: 'Grid',
          icon: 'fas fa-th',
          itemsData: []
        }
      }
    },
    gridSubtitle: Array,
    gridEvents: Array
  },
  data () {
    return {
      noItemsView: {noItems: 'Nenhum registro encontrado!'},
    }
  },
  methods: { 
    //Component Methods
    /*getClass(payload) {
      return this.getSubtitleClass(this.gridSubtitle, payload)
    },*/
    getEvent(payload) {
      let event = fn.getCustomEvents(this.gridEvents, payload) 
      if (Object.entries(event).length > 0) { 
        return event.customEventName
      } else {
        return null
      }
    },

    setEvent(event,item) {
      let obj = {}
      obj.method = event.method
      obj.record = item

      this.$emit("set-custom-event", obj)
      this.customEvent = null
    },

    checkCondition(payload) {
      let events = []
      let checks = []

      this.gridEvents.forEach(x => {
        let obj = {}
         if(x.values.length === 0) {
          obj.label = x.event
          obj.method = x.method
          events.push(obj)
         } else {
          //Percorrendo aas condicoes do event
           x.values.forEach(v => {
            if(payload.hasOwnProperty(`${v.attribute}`)) {             
              //Conditional IS-NULL
              if(v.condition === 'IS-NULL') {
                if(payload[`${v.attribute}`] === null) {
                  checks.push(true)
                } else {
                  checks.push(false)
                }
              }
              //Conditional NOT-NULL
              if (v.condition === 'NOT-NULL') {
                let str = payload[`${v.attribute}`]
                if(str !== null) {
                  checks.push(true)
                } else {
                  checks.push(false)
                }
              }              
            }
           })

            //Verificando se existem valores diferente de TRUE, caso haja, não exibir o event
            if(!checks.some(elem => elem !== true)){
              obj.label = x.event
              obj.method = x.method
              events.push(obj)
            }
            checks = []
         }         
      })
      return events
    },


    //Triggers Emit Events
    gridRowSelected(item) {
      this.$emit("grid-row-selected", item)
    },
    gridRowActionSelected(item) {
      this.$emit("grid-row-action-selected", item)
    },
    findRowSelected(item) {
      this.$emit("row-find-selected", item) 
    },
    deleteRowSelected(item) {
      this.$emit("row-delete-selected", item) 
    },
    gridDbClick (item) {
      this.$emit("grid-dbclick", item) 
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        this.itemsData = items        
      }
    }
  }
}
</script>
