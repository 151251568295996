import axios from 'axios'
import store from '@/store'
const equipment = "api/v01/internal/equipment"

const findAllEquipmentByType = (payload) => {
    let obj = []  
    let param = {}
    param.equipment_type_dm = payload
    const url = `${store.getters.restrictURI}/${equipment}/find-all-equipment-by-type`
    axios.post(url, param)
    .then(res => {
        res.data.forEach(e => {
            obj.push({
                value: e.equipment_id,
                label: e.equipment_name 
            })
        })
        obj.unshift({ value: null, label: "Selecione . . . " })
    })
    .catch(error => {
        console.log(error)
    })
    return obj
}

export default { findAllEquipmentByType }
export {findAllEquipmentByType }