import axios from 'axios'
import store from '@/store'


const initializeFind = (payload) => {
    getFindFields(payload)
    findLoadRecord(payload)
}

const getFindFields = (payload) => {
    const url = `${store.getters.restrictURI}/${payload.COMPONENT_FIND.endPoint}/fields`
    axios.post(url, {})
        .then(res => {
            payload.fields = res.data[0].find
        })
}

const findLoadRecord = (payload) => {  
    if(payload.COMPONENT_FIND.valueKey !== null) {
        let paramFilter = {}
        paramFilter[`${payload.COMPONENT_FIND.primaryKey}`] = `${payload.COMPONENT_FIND.valueKey}`
        const url = `${store.getters.restrictURI}/${payload.COMPONENT_FIND.endPoint}/find`
        axios.post(url, paramFilter)
        .then(res => {
            payload.recordFind = res.data[0]
        })
    } 
}

const getFindRecordsByFilters = (payload) => {
    if(payload.filter.apply) {
        const url = `${store.getters.restrictURI}/${payload.COMPONENT_FIND.endPoint}/find-all-by-filter`
        axios.post(url, payload.filter)
            .then(res => {
                if(res.data[0][`${payload.COMPONENT_FIND.primaryKey}`] !== null) {
                    payload.items = res.data
                } else {
                    payload.items = []
                }
            })
    } else if (payload.filter.reset) {
      payload.items = []  
    }
    payload.filter = {}
}

const resetFind = (payload) => {
    payload.showModal = false
    payload.filter = {}
    //payload.fields = []
    payload.items = []
    payload.recordFind = {}
    payload.rowFindSelected = {}
}

export default { initializeFind, getFindRecordsByFilters, resetFind }
export { initializeFind, getFindRecordsByFilters, resetFind }