import axios from 'axios'
import store from '@/store'
const generalParameter = "api/v01/internal/general-parameter"

const findAllGeneralParameter = () => {
    let obj = []  
    const url = `${store.getters.restrictURI}/${generalParameter}/find-all`
    axios.post(url)
    .then(res => {
        res.data.forEach(e => {
            obj.push({
                value: e.general_parameter_id,
                label: e.description 
            })
        })
        obj.unshift({ value: null, label: "Selecione . . . " })
    })
    .catch(error => {
        console.log(error)
    })
    return obj
}

export default { findAllGeneralParameter }
export {findAllGeneralParameter }