import axios from 'axios'
import store from '@/store'

const fileStorage = 'api/v01/origin/tasy/file-storage-path'

const findAllByFileStorage = (payload) => {
    let obj = []  
    let param = {}
    param.nm_storage = payload
    const url = `${store.getters.restrictURI}/${fileStorage}/find-all-by-file-storage`
    axios.post(url,param)
        .then(res => {
            res.data.forEach(e => {
                obj.push({
                    value: e.cd_uuid,
                    label: e.cd_uuid
                })
            })
            obj.unshift({ value: null, label: "Selecione . . . " })
        })
        .catch(error => {
            console.log(error)
        })
    return obj
}

export default {findAllByFileStorage}
export {findAllByFileStorage}

