import axios from 'axios'
import store from '@/store'

const empresa = 'api/v01/origin/tasy/empresa'
const estabelecimento = 'api/v01/origin/tasy/estabelecimento'

const findAllEmpresas = () => {
    let obj = []  
    const url = `${store.getters.restrictURI}/${empresa}/find-all-empresas`
    axios.post(url)
        .then(res => {
            res.data.forEach(e => {
                obj.push({
                    value: e.cd_empresa.toString(),
                    label: e.nm_razao_social
                })
            })
            obj.unshift({ value: null, label: "Selecione . . . " })
        })
        .catch(error => {
            console.log(error)
        })
    return obj
}


const findAllEstabelecimentosByEmpresas = (payload) => {
    let obj = [] 
    let param = {}
    let urlService = null

    if(payload !== null) {
        param.cd_empresa =  payload
        urlService = 'find-all-estabelecimentos-by-empresa'
    } else {
        urlService = 'find-all-estabelecimentos'
    }
    const url = `${store.getters.restrictURI}/${estabelecimento}/${urlService}`
    axios.post(url,param)
        .then(res => {
            res.data.forEach(e => {
                obj.push({
                    value: e.cd_estabelecimento.toString(),
                    label: `${e.cd_cgc} - ${e.ds_razao_social}` 
                })
            })
            obj.unshift({ value: null, label: "Selecione . . . " })
        })
        .catch(error => {
            console.log(error)
        })
    return obj
}

export default {findAllEmpresas, findAllEstabelecimentosByEmpresas}
export {findAllEmpresas, findAllEstabelecimentosByEmpresas}

