import finds from "./modules/_finds"
import {initializeFind, getFindRecordsByFilters, resetFind} from "./app/component-find"
import {initializeComponent, getRecords, getRecordsByMethod, getRecordsByFilters, loadRecord, reset, setAction} from "./app/functions"
import {getEvents, getCustomEvents, executeCustomEvent} from "./app/events"

export default { 
    finds,
    initializeFind, getFindRecordsByFilters, resetFind,
    initializeComponent, getRecords, getRecordsByMethod, getRecordsByFilters, loadRecord, reset, setAction,
    getEvents, getCustomEvents, executeCustomEvent
 }
