import store from '@/store'

/*
========================================================================== 
MODE
--------------------------------------------------------------------------
  - Utilizado para definir o modo de exibição do componente
--------------------------------------------------------------------------
    A: ALERT
    L: LOADING
    M: MODAL
========================================================================== 
*/
//const mode = ['A', 'L', 'M'];

const MESSAGE_GENERIC = 'Ops... Encontramos um problema.';

//const MESSAGE_400 = 'Encontramos um problema ao tentar realizar o processo.';
//const MESSAGE_500 = 'Encontramos um problema ao comunicar com o servidor. Entre em contato com o Administrador do Sistema.';
const TIP_GENERIC = 'Ente em contato com o Administrador do Sistema.';

const BAD_REQUEST = 'Não foi possível excutar o método. Verifique os detalhes para maiores informações.';
const INVALID_ACTION = 'Não foi possível localizar o método na controller.Verifique';

const SUCCESS_TITLE = 'Operação realizada com sucesso!'
const WARNING_TITLE = 'Opsss... Encontramos um problema!'
const ERROR_TITLE = 'Opsss... Falhou!'
const GENERIC_TITLE = 'Informação'

const ERRORS = [
    { in:'Bad Request', out: BAD_REQUEST, tip: TIP_GENERIC },
    { in:'Invalid Action', out: INVALID_ACTION, tip: 'Verifique as informações e/ou métodos da Controller.' }
]

const DEFAUT_MODE = 'M'
const AUTO_CLOSE = false
const COLLAPSED_ERROR = true


const showMessage = (payload) => {
    let msg = {}
    msg.show = true
    msg.mode = DEFAUT_MODE
    msg.type = setType(payload.status)
    msg.title = setTitle(payload.status)
    msg.subtitle = handleMessage(payload.statusText)
    msg.error = splitError(payload.data)
    msg.autoClose = AUTO_CLOSE,
    msg.isCollapsed = COLLAPSED_ERROR
    store.commit('setMessage', msg)

}

const closeMessage = () => {
    let msg = {}
    msg.show = false
    msg.mode = null
    msg.type = null
    msg.title = null
    msg.subtitle = null
    msg.error = []
    msg.autoClose = AUTO_CLOSE
    msg.isCollapsed = COLLAPSED_ERROR
    store.commit('setMessage', msg)
}


const showLoading = () => {
    let msg = {}
    msg.show = true
    msg.mode = 'L'
    store.commit('setMessage', msg)
}

/*
========================================================================== 
SET VALUES
========================================================================== 
*/
const setType = (payload) => {
    let value = null
    switch (payload) {
        case 200:
            value = 'success'
            break;
        case 400:
            value = 'warning'
            break;
        case 500:
            value = 'error'
            break;
        default:
            value = 'info'
    }
    return value
}

const setTitle = (payload) => {
    let value = null
    switch (payload) {
        case 200:
            value = SUCCESS_TITLE
            break;
        case 400:
            value = WARNING_TITLE
            break;
        case 500:
            value = ERROR_TITLE
            break;
        default:
            value = GENERIC_TITLE
    }
    return value
}

const handleMessage = (payload) => {
    let message = {}
    let find = null
    
    switch (payload) {
        case payload.match(/^<!doctype/)?.input:
            find = '<!doctype'
            break;
        case payload.match(/^Invalid Action/)?.input:
            find = 'Invalid Action'
            break;
        case payload.match(/^Bad Request/)?.input:
            find = 'Bad Request'
            break;
    }
    message = ERRORS.filter(e => {return e.in === find})[0]
    return message.out || MESSAGE_GENERIC
}

const splitError = (payload) => {    
    let errors = []
    let stringReplace = '"'
    errors.push(payload.replaceAll(stringReplace,'').split('\n')) 
    return errors[0]    
}

export default { showMessage, closeMessage,  }
export { showLoading, showMessage, closeMessage,  }