import { findAllComponent,findAllComponentByProduct } from '@/js/modules/component.js'
import { findAllDomainByProducts } from '@/js/modules/domain.js'
import { findValuesDomain, findAlternativeValuesDomain, findValuesUnifiedDomain } from '@/js/modules/domain-item.js'
import { findAllEquipmentByType } from '@/js/modules/equipment.js'
import { findAllGeneralParameter } from '@/js/modules/general-parameter.js'
import { findMacros } from '@/js/modules/macro.js'
import { findProducts } from '@/js/modules/product.js'
import { findSeal } from '@/js/modules/seal.js'
import { findSoftware } from '@/js/modules/software.js'
import { findSoftwareEvent } from '@/js/modules/software-event.js'
import { findAllEmpresas, findAllEstabelecimentosByEmpresas } from '@/js/modules/tasy.js'

import { findAllFileStorage, findAllByFileStorage } from '@/js/modules/his/tasy/index.js'

export default {
    findAllComponent,
    findAllComponentByProduct,
    findAllDomainByProducts,
    findAllEmpresas,
    findAllEquipmentByType,
    findAllEstabelecimentosByEmpresas,
    findAllGeneralParameter,
    findAlternativeValuesDomain, 
    findMacros,
    findProducts,
    findSeal,
    findSoftware,
    findSoftwareEvent,
    findValuesDomain, 
    findValuesUnifiedDomain,
    findAllFileStorage,
    findAllByFileStorage
}
