<template>
   <div>
    <CCard>
      <CCardHeader class="_card-header-filter">
        <i class="fas fa-filter mx-1"></i> Filtros
        <div class="card-header-actions">
          <!--<CLink href="#" class="card-header-action btn-close"  @click="$store.commit('toggleFilter')">
            <CIcon name="cil-x-circle" />
          </CLink> -->
        </div>
      </CCardHeader>

      <CCardBody class="_card-body-filter">
        <CRow>
          <CCol sm="12">
            <slot name="default"></slot>
          </CCol>
        </CRow>
      </CCardBody>

      <CCardFooter class="_card-footer-filter">
        <div class="card-header-actions">
          <CLink href="#" class="card-header-action btn-close mx-2 text-danger"  @click="setAction('resetFilter') ">
            <i class="fas fa-eraser"></i> Limpar
          </CLink>
          <CLink href="#" class="card-header-action btn-close text-success" @click="setAction('applyFilter')">
            <i class="fas fa-search"></i> Pesquisar
          </CLink>
        </div>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "FormFilter",
  data () {
    return {
      action: null
    }
  },

  methods: { 
    setAction(payload) {
      this.action = payload
      this.$emit("set-action-filter", this.action)
      this.action = null
    },
  },

};
</script>
<style scoped>

