<template>
  <div>    
    <CCard>
      <CCardHeader class="_card-header-detail">
        <i class="fas fa-list mx-1"></i> Detalhe
        <div class="card-header-actions">
          <!--<CDropdown color="secondary" size="sm" split toggler-text="Small Split" >
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
          </CDropdown>-->
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>          
          <CCol sm="12">
            <slot name="default"></slot>
          </CCol>
         </CRow>
      </CCardBody>
      <CCardFooter class="_card-footer">    
        <CRow>
          <CCol sm="12" >
            <i class="fas fa-user-alt mr-1"></i> Incluso por <strong>{{tracer._create_user}}</strong> em <strong>{{moment(tracer.create_dt).format('DD/MM/YYYY HH:mm:ss')}}</strong>  
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" v-show="tracer.update_dt !== null">
            <i class="fas fa-user-edit"></i> Alterado por <strong class="text-upper">{{tracer._update_user}}</strong> em <strong>{{moment(tracer.update_dt).format('DD/MM/YYYY HH:mm:ss')}}</strong>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>
<script>
export default {
  name: "FormDetail",
  props: {
    tracer: {
      type: Object,
      default() {
        return {
          _create_user: null,
          create_dt: null,
          _update_user: null,
          update_dt: null 
        }
      }
    }
  }
};
</script>
