import axios from 'axios'
import store from '@/store'
const seal = "api/v01/internal/seal"

const findSeal = () => {
    let obj = []  
    const url = `${store.getters.restrictURI}/${seal}/find-all`
    axios.post(url)
        .then(res => {
            res.data.forEach(e => {
                obj.push({
                    value: e.seal_id,
                    label: e.seal_name
                })
            })
            obj.unshift({ value: null, label: "Selecione . . . " })
        })
        .catch(error => {
            console.log(error)
        })
    return obj
}

export default { findSeal }
export {findSeal }