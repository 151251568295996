import axios from 'axios'
import store from '@/store'
const product = "api/v01/internal/product"

const findProducts = () => {
    let obj = []  
    const url = `${store.getters.restrictURI}/${product}/find-all`
    axios.post(url)
        .then(res => {
            res.data.forEach(e => {
                obj.push({
                    value: e.product_id,
                    label: e.product_presentation
                })
            })
            obj.unshift({ value: null, label: "Selecione . . . " })
        })
        .catch(error => {
            console.log(error)
        })
    return obj
}

export default { findProducts }
export {findProducts }