import axios from 'axios'
import store from '@/store'
import moment from "moment"
import { setButtonInit, setButtonNewRow, setButtonEdit } from '@/js/app/toolbars.js'
import { showLoading, showMessage, closeMessage } from '@/js/app/messages.js'

/*=============================================================================================
 * Método de inicialização dos componentes
=============================================================================================*/
const initializeComponent =  (payload) => {   
    showLoading() 
    getFields(payload)
    setButtonInit(payload) 
    store.commit('toggleFilter', false)
}

/*=============================================================================================
 * Método para montagem do GRID 
=============================================================================================*/
const getFields = (payload) => {
    const url = `${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/fields`
    axios.post(url, {})
        .then(res => {
            payload.fields = res.data[0][`${payload.COMPONENT.config.fields}`]
            closeMessage()
        })
        .catch(error => {
            showMessage(error.response)
        }) 
}
/*=============================================================================================
 * Método para recuperar registros confome método inicial
=============================================================================================*/
const getRecords = (payload) => {
    showLoading()
    let paramFilter = {}
    let initial = (payload.COMPONENT.config.initialMethod !== null && payload.COMPONENT.config.initialMethod !== undefined)? payload.COMPONENT.config.initialMethod : 'find-all'
    let urlService = payload.COMPONENT.config.parentService !== undefined ? `${payload.COMPONENT.config.parentService}` : initial
    
    if(payload.COMPONENT.config.child === true && (payload.COMPONENT.config.parentKey !== null || payload.COMPONENT.config.parentKey!== undefined)) {
        //paramFilter[`${payload.COMPONENT.config.parentKey}`] = payload.parent[`${payload.COMPONENT.config.parentValue}`]
        paramFilter[`${payload.COMPONENT.config.parentKey}`] = payload.parent.parentValue
    }
    const url = `${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/${urlService}`
    axios.post(url, paramFilter)
        .then(res => {
            if(res.data[0][`${payload.COMPONENT.primaryKey}`] !== null && res.data[0][`${payload.COMPONENT.primaryKey}`] !== undefined) {
                payload.items = res.data
            } else {
                payload.items = []
            }   
            closeMessage()
        })
        .catch(error => {
            showMessage(error.response)
        })          
}

/*=============================================================================================
 * Método para recuperar registros confome método inicial e filter adicional 
=============================================================================================*/
const getRecordsByMethod = (payload, param, method) => {    
    showLoading()
    let initial = method !== null && method !== undefined ? method : payload.COMPONENT.config.initialMethod
    //let initial = (payload.COMPONENT.config.initialMethod !== null && payload.COMPONENT.config.initialMethod !== undefined)? payload.COMPONENT.config.initialMethod : 'find-all'

    const url = `${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/${initial}`
    axios.post(url,param)
        .then(res => {          
            if(res.data[0][`${payload.COMPONENT.primaryKey}`] !== null && res.data[0][`${payload.COMPONENT.primaryKey}`] !== undefined) {
                payload.items = res.data
            } else {
                payload.items = []
            }
            closeMessage()
        })
        .catch(error => {
            showMessage(error.response)
        })          
}

/*=============================================================================================
 * Método para recuperar registros confome aplicação dos filters 
=============================================================================================*/
const getRecordsByFilters = (payload) => {
    showLoading()
    if(payload.filter.apply) {
        if(payload.COMPONENT.config.child === true && (payload.COMPONENT.config.parentKey !== null || payload.COMPONENT.config.parentKey!== undefined)) {
            payload.filter[`${payload.COMPONENT.config.parentKey}`] = payload.parent[`${payload.COMPONENT.config.parentValue}`]
        }
        const url = `${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/find-all-by-filter`
        axios.post(url, payload.filter)
            .then(res => {
                if(res.data[0][`${payload.COMPONENT.primaryKey}`] !== null) {
                    payload.items = res.data
                } else {
                    payload.items = []
                }
                closeMessage()
            })
            .catch(error => {
                showMessage(error.response)
            })   
    } else if (payload.filter.reset) {
        getRecords(payload)
    }    
    payload.filter = {}
}

/*=============================================================================================
 * Método utilizado para recuperar o registro selecionado no GRID
=============================================================================================*/
const loadRecord = (payload) => {  
    showLoading()
    const url = `${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/find`
    axios.post(url, payload.gridRowSelected)
        .then(res => {
            payload.record = res.data[0]
            afterLoadRecord(payload)
        })
        .catch(error => {
            showMessage(error.response)
        })   
}

const afterLoadRecord = (payload) => {
    editingControl(payload)
    setButtonEdit(payload)
    payload.items = []
    payload.gridRowSelected = {}
    closeMessage()
}

/*=============================================================================================
 * Método utilizado para RESET das variaveis do contexto
=============================================================================================*/
const reset = (payload) => {
    setButtonInit(payload)
    payload.showGrid = true
    payload.readOnly = false
    payload.toolbars = {}
    payload.filter = {}
    payload.record = {}
    payload.gridRowSelected = {}
    payload.gridDbclick = {}
    payload.items = []

    if(payload.COMPONENT.finds.length > 0 ) {
        payload.COMPONENT.finds.forEach(find => {
            payload[`${find}`] = null 
        })
    }   
}

/*=============================================================================================
 * Método utilizado SET das ACTIONS da TOOLBARS
=============================================================================================*/
const setAction = (payload) => {
    if (payload.toolbars === "add") {
        newRecord(payload);
    } else if (payload.toolbars === "save") {
        saveRecord(payload)
    } else if (payload.toolbars === "cancel") {
        cancelRecord(payload)
    } else if (payload.toolbars === "delete") {
        deleteRecord(payload)
    } else if (payload.toolbars === "refresh") {
        refreshRecord(payload)
    } 
}

/*=============================================================================================
 * Método utilizado para habilitar o componente para novos registros
=============================================================================================*/
const newRecord = (payload) => {
    payload.showGrid = false
    payload.items = []
    setButtonNewRow()
    if(payload.COMPONENT.config.child === true && (payload.COMPONENT.config.parentKey !== null || payload.COMPONENT.config.parentKey!== undefined)) {
        //payload.record[`${payload.COMPONENT.config.parentKey}`] = payload.parent[`${payload.COMPONENT.config.parentValue}`]
        payload.record[`${payload.COMPONENT.config.parentKey}`] = payload.parent.parentValue
    }       
}

/*=============================================================================================
 * Método utilizado para habilitar SALVAR registro
=============================================================================================*/
const saveRecord = (payload) => {
    showLoading()
    const apiAction = {}.hasOwnProperty.call(payload.record,`${payload.COMPONENT.primaryKey}`)? "update" : "insert"
    const url = `${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/${apiAction}`
    axios.post(url, payload.record)
        .then(res => {
            afterSaveRecord(payload, res)
        })
        .catch(error => {
            afterSaveRecord(payload, error.response)           
        })
}

const afterSaveRecord = (payload, response) => {
    payload.toolbars = {}
    if(response.status === 200) {
        reset(payload)
        getRecords(payload)    
    } 
    showMessage(response)
}

/*=============================================================================================
 * Método utilizado para cancelar a edicao do registro
=============================================================================================*/
const cancelRecord = (payload) => {
    reset(payload)
    getRecords(payload)
}

/*=============================================================================================
 * Método utilizado para DELETAR registro selecionado no GRID
=============================================================================================*/
const deleteRecord = (payload) => {
    showLoading()
    const apiAction = payload.COMPONENT.config.delete.method   
    const url = `${store.getters.restrictURI}/${payload.COMPONENT.endPoint}/${apiAction}`
    axios.post(url, payload.record)
      .then(res => {
          afterDeleteRecord(payload,res)
      })
      .catch(error => {
        afterDeleteRecord(payload, error.response)
      })
}

const afterDeleteRecord = (payload, response) => {
    payload.toolbars = {}
    if(response.status === 200) {
        reset(payload)
        getRecords(payload)
    }
    showMessage(response)
}

/*=============================================================================================
 * Método utilizado para Atualizar registro selecionado no GRID
=============================================================================================*/
const refreshRecord = (payload) => {
    this.getRecords(payload)
}

const editingControl = (payload) => {
    payload.showGrid = !payload.showGrid
    if(payload.record[`${payload.COMPONENT.config.delete.atribute}`] !== undefined) {
        payload.readOnly = payload.record[`${payload.COMPONENT.config.delete.atribute}`] !== null ? false:true
    } else {
        payload.readOnly = false
    }
}

export default {initializeComponent, getRecords, getRecordsByMethod, getRecordsByFilters, loadRecord, reset, setAction}
export {initializeComponent, getRecords, getRecordsByMethod, getRecordsByFilters, loadRecord, reset, setAction}

/*=============================================================================================
 * Método utilizado para Atualizar registro selecionado no GRID
=============================================================================================*/
/*


const getSubtitleClass = (options,filter) => {
    let cSubtitle = []
    if (Object.entries(options).length > 0) {        
        cSubtitle = options.filter(e => e.status === filter)
        if (Object.entries(cSubtitle).length > 0) {
            return cSubtitle[0].class
        } else {
            return 'text-white'
        }
    }
}

const setMessageModal = (payload,response) => {
    let modal = {}
    modal.show = true
    if(response.status === 200) {
        modal.type = 'success'
        modal.title = 'Sucesso...'
        modal.message = `ID do Registro: ${response.data[0][`${payload.COMPONENT.primaryKey}`]}`
    } else if (response.status === 400) {
        modal.type = 'warning'
        modal.title = 'Atenção'
        modal.message = 'Necessário ajuste antes de continuar...'
        let errorList = response.data.split("\n");
        modal.error = errorList
    } 
    store.commit("setMessageModal", modal)
}

const toggleLoading = () => {
    store.commit("toggleLoading")
}
*/


