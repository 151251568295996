<template>
  <div >
    <CRow>
      <CCol sm="1">
        <CNav>
          <CButton size="sm" color="danger" class="_btn-toolbars-only-icon mr-2" @click="$store.commit('toggleFilter')" v-if="showDefault">
            <CIcon v-if="showFilter" name="cil-filter-x"/>
            <CIcon v-else-if="!showFilter" name="cil-filter"/>
          </CButton>
          <!--
          <CButton v-if="showFilter===false" size="sm" color="danger" class="_btn-toolbars-only-icon mr-2" @click="$store.commit('splitDisplay')">
            <i class="fas fa-columns"></i>
          </CButton>
          -->
        </CNav>
      </CCol>
      <CCol sm="11">
        <CNav class="bp-toolbars float-right">
          <CButton size="sm" color="blue-dark" class="_btn-toolbars mr-2" :disabled="buttonControl.add" @click="setAction('add')" v-if="showDefault">
            <i class="fas fa-plus-circle mx-2"></i> Novo
          </CButton>
          <CButton size="sm" color="blue-dark" class="_btn-toolbars mr-2" :disabled="buttonControl.save" @click="setAction('save')" v-if="showDefault">
            <i class="fa fa-check-circle mx-2"></i> Salvar
          </CButton>
          <CButton size="sm" color="blue-dark" class="_btn-toolbars mr-2" :disabled="buttonControl.cancel" @click="setAction('cancel')" v-if="showDefault">
            <i class="fa fa-undo mx-2"></i> Cancelar
          </CButton>
          <CButton size="sm" color="blue-dark" class="_btn-toolbars mr-2" :disabled="buttonControl.delete" @click="setAction('delete')" v-if="showDefault">
            <i class="fa fa-trash mx-2"></i> Excluir
          </CButton>
          <CButton size="sm" color="danger"  class="_btn-toolbars-only-icon ml-4" @click="close">
            <i class="fas fa-times mx-2"></i>
          </CButton>
        </CNav>        
      </CCol>
    </CRow>
    <!-- Modal Component -->
    <!--<MessageModal></MessageModal>-->
    <!--<ConfirmationModal :showModal="showModalConfirmation" :objConfirmation="objConfirmation"  @setReturnConfirmation="objReturnConfirmation = $event" ></ConfirmationModal> -->
  </div>
</template>
<script>
import { buttonControl } from '@/js/app/toolbars.js'
//import MessageModal from "@/components/MessageModal"
//import ConfirmationModal from "@/components/ConfirmationModal"

export default {
  name: 'Toolbars',
  components: { /*MessageModal*/ },
  props: { 
    showDefault: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showFilter() {
      return this.$store.state.showFilter
    },
  },
  data () {
    return {
      buttonControl
    }
  },
  methods: {
    setAction(payload) {
      this.$emit("set-action-toolbars", payload)   
    },
    close() {
      this.$router.push({ name: "Home" });
    }
  },
  watch: {
    buttonControl: {
      immediate: true,
      handler() {
      } 
    }
  }

}
</script>