import axios from 'axios'
import store from '@/store'
const endpoint = 'api/v01/internal/domain-item'

const findValuesDomain = (domain) => {
    let obj = []   
    let paramFilter = {}
    paramFilter.domain_id = domain

    const url = `${store.getters.restrictURI}/${endpoint}/find-values-domain`
    axios.post(url, paramFilter)
    .then(res => {
        res.data.forEach(d => {
        obj.push({
            value: d.domain_item_id,
            label: d.item_value
            })
        })
        obj.unshift({ value: null, label: "Selecione . . . " })
    })
    .catch(error => {
        console.log(error)
    })
    return obj
}

const findAlternativeValuesDomain = (domain) => {
    let obj = []  
    let paramFilter = {}
    paramFilter.domain_id = domain

    const url = `${store.getters.restrictURI}/${endpoint}/find-alternative-values-domain`
    axios.post(url, paramFilter)
    .then(res => {
        res.data.forEach(d => {
        obj.push({
            value: d.domain_item_id,
            label: d.item_alternative_value
            })
        })
        obj.unshift({ value: null, label: "Selecione . . . " })
    })
    .catch(error => {
        console.log(error)
    })
    return obj
}

const findValuesUnifiedDomain = (domain) => {
    let obj = []  
    let paramFilter = {}
    paramFilter.domain_id = domain

    const url = `${store.getters.restrictURI}/${endpoint}/find-valores-unificados-dominio`
    axios.post(url, paramFilter)
    .then(res => {
        res.data.forEach(d => {
        obj.push({
            value: d.domain_item_id,
            label: d.item_value,
            find:  d.item_alternative_value
            })
        })
        obj.unshift({ value: null, label: "Selecione . . . " })
    })
    .catch(error => {
        console.log(error)
    })
    return obj
}

export default { findValuesDomain, findAlternativeValuesDomain, findValuesUnifiedDomain }
export { findValuesDomain, findAlternativeValuesDomain, findValuesUnifiedDomain }